import { AuthContainer } from './iksu/components/AuthContainer';
import { AuthProvider } from './iksu/contexts/AuthContext';

function App() {
  return (
    <AuthProvider>
      <AuthContainer />
    </AuthProvider>
  );
}

export default App;

import { ApiResponse } from "../types/ApiResponse";

export const getData = async <T>(
  url: string,
  headers?: HeadersInit,
  type: "json" | "blob" = "json"
): Promise<ApiResponse<T>> => {
  const res = await fetch(url, {
    method: "GET",
    headers,
  });

  if (res.status === 204) {
    return new ApiResponse<T>(res.status);
  }

  if (res.status === 401) {
    return new ApiResponse<T>(res.status);
  }

  if (res.status === 404) {
    return new ApiResponse<T>(res.status);
  }

  const data = await (type === "json" ? res.json() : res.blob());
  return new ApiResponse<T>(res.status, data);
};

export const postData = async <T>(
  url: string,
  data: Record<string, any>,
  headers: HeadersInit
): Promise<ApiResponse<T>> => {
  const res = await fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(data),
    headers,
  });

  if (res.status === 401) {
    return new ApiResponse<T>(res.status);
  }
  const responseData = await res.json();
  return new ApiResponse<T>(res.status, responseData);
};

export const deleteData = async (
  url: string,
  headers: HeadersInit
): Promise<ApiResponse> => {
  const res = await fetch(url, {
    method: "DELETE",
    headers,
  });

  return new ApiResponse(res.status);
};

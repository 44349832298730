import React, {createContext, useState, useEffect} from 'react';

interface AuthContextProps {
    isAuthenticated: boolean;
    login: () => void;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
    isAuthenticated: false,
    login: () => {},
    logout: () => {}
});

export const AuthProvider: React.FC = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

     useEffect(() => {
         if (localStorage.getItem('remember-me') !== null) {
            setIsAuthenticated(true);
         }
    }, []);

    const login = () => {
        localStorage.setItem('remember-me', 'true')
        setIsAuthenticated(true);
    }

    const logout = () => {
        localStorage.removeItem('remember-me')
        setIsAuthenticated(false);
    }

    return (
        <AuthContext.Provider value = {{ isAuthenticated, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}





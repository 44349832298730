export class ApiResponse<T = unknown> {
    constructor(statusCode: number, data?: T) {
        this.statusCode = statusCode;
        this.data = data;
    }

    get success(): boolean {
        return this.statusCode < 300 && this.statusCode >= 200;
    }

    statusCode: number;
    data?: T;
}
import { ApiResponse } from "../types/ApiResponse";
import { getData, postData } from "./getData";

const loginUrl = "/iksu/login";
const logoutUrl = "/iksu/logout";

export const loginUser = (
  username: string,
  password: string
): Promise<ApiResponse<any>> =>
  postData(
    loginUrl,
    {
      username: username,
      password: password,
    },
    createHeaders()
  );

export const logoutUser = (): Promise<ApiResponse<any>> => {
  return getData(logoutUrl);
};

const createHeaders = (): HeadersInit => {
  const headers = new Headers();
  headers.set("Pragma", "no-cache");
  headers.set("Access-Control-Allow-Origin", "*");
  headers.set("Content-Type", "application/json");
  return headers;
};

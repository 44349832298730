import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import * as AuthClient from "../../clients/AuthClient";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import styles from "./Login.module.css";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#3e3d40",
    border: 0,
    borderRadius: 3,
    boxShadow: "none",
    color: "white",
    height: 48,
    padding: "0 30px",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#4b4950",
      "@media (hover: none)": {
        backgroundColor: "#4b4950",
      },
    },
  },
});

interface LoginProps {}

export const Login: React.FC<LoginProps> = () => {
  const classes = useStyles();
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    const result = await AuthClient.loginUser(username, password);

    if (result.success) {
      login();
    } else {
    }
  };

  return (
    <div className={styles.loginContainer}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className={styles.authContainer}>
          <TextField
            size="small"
            label="Användarnamn"
            onChange={(e) => {
              setUsername(e.currentTarget.value);
            }}
            spellCheck={false}
          />
          <TextField
            size="small"
            label="Lösenord"
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
            spellCheck={false}
            type="password"
          />
          <Button
            className={classes.root}
            type="submit"
            variant="contained"
            onClick={() => {
              handleLogin();
            }}
          >
            Logga in
          </Button>
        </div>
      </form>
    </div>
  );
};

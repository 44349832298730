import { Skeleton } from "@mui/material";
import React, { useContext, Suspense } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Login } from "./Auth/Login";
import styles from "./AuthContainer.module.css";

interface AuthContainerProps {}

const IKSUContainer = React.lazy(() =>
  import("./IKSUContainer").then(({ IKSUContainer }) => ({
    default: IKSUContainer,
  }))
);

export const AuthContainer: React.FC<AuthContainerProps> = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
      {isAuthenticated ? (
        <Suspense
          fallback={
            <div className={styles.skeletonContainer}>
              <Skeleton height={80} sx={{ bgcolor: "#fff" }} />
              <Skeleton height={80} sx={{ bgcolor: "#fff" }} />
              <Skeleton height={80} sx={{ bgcolor: "#fff" }} />
              <Skeleton height={80} sx={{ bgcolor: "#fff" }} />
              <Skeleton height={80} sx={{ bgcolor: "#fff" }} />
            </div>
          }
        >
          <IKSUContainer />
        </Suspense>
      ) : (
        <Login />
      )}
    </>
  );
};
